import React, { useEffect, useState } from 'react'
import { getApi } from '../../../utils/services';
import { BILLS_API, BUDGET_RECORD, CONTRACT_LIST, PENDING_REPORTS, SPEND_ITEM, TOTAL_EXPENSE, VENDOR, VENDOR_SPEND } from '../../../utils/apiRoutes';
import { useFeedsColumnsVendor } from '../../../utils/helpers/tableHelpers/useFeedsColumnsVendor';
import { useFeedsColumnContracts } from '../../../utils/helpers/tableHelpers/useFeedColumnContracts';
import { useFeedsColumnBills } from '../../../utils/helpers/tableHelpers/useFeedColumnBills';
import DashboardCard from '../../common/DashboardCard';
import { VENDOR_VP_PENDING } from '../../../utils/constant';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';
import { useFeedsColumnIndividualBills } from '../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill';
import MultipleBarChart from '../../charts/multiplebarchart/MultipleBarChart'
import PieChart from '../../charts/piechart/PieChart';
import PieDonutChart from '../../charts/piedonutchart/PieDonutChart';
import LineColumnChart from '../../charts/linechart/LineColumnChart';
import LineChart from '../../charts/linechart/LineChart'
import exp1 from '../../../assets/image/Totalexp1.png';
import exp2 from '../../../assets/image/Totalexp2.png';
import exp3 from '../../../assets/image/Totalexp3.png';
import pay1 from '../../../assets/image/PaymentPending1.png';
import pay2 from '../../../assets/image/PaymentPending2.png';
import pay3 from '../../../assets/image/PaymentPending3.png';
import GroupedBarChart from '../../charts/barchart/GroupedBarChart';

const VpDashboard = () => {
  const [vendors, setVendor] = useState([]);
  const [purchaseContract, setPurchaseContarct] = useState([]);
  const [bills, setBills] = useState([]);
  const [individualBill, setIndividualBills] = useState([]);
  const [loader, setLoader] = useState(false);
  const [reportsPieChart, setReportsPieChart] = useState();
  const [label, setLabel] = useState();
  const [expenseData, setExpencePayment] = useState({});
  const [budgetRecord, setBudgetRecord] = useState([]);
  const [budgetMonths, setBudgetMonths] = useState([]);
  const [spendTrend, setSpendTrend] = useState([]);
  const [vendorSpend, setVendorSpend] = useState([]);
  const [vendorName, setVendorName] = useState([]);
  const [spendItemName, setSpendItemName] = useState([]);
  const [spendTotalAmout, setSpendTotalAmout] = useState([]);

  const handleVendorList = async () => {
    try {
      setLoader(true)
      const result = await getApi(VENDOR);
      const vendors = result.res.vendor_list.filter((item) => item.Status === VENDOR_VP_PENDING);
      setLoader(false)
      setVendor(vendors)
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useFeedsColumnsVendor(handleVendorList);

  const handlerPurchaseContractList = async () => {
    try {
      setLoader(true)
      let result = await getApi(CONTRACT_LIST);
      result = result.res.contract_list.filter((item) => item.Status === VENDOR_VP_PENDING);
      setLoader(false)
      setPurchaseContarct(result)
    } catch (error) {
      console.log(error);
    }
  }

  const purchasecolumns = useFeedsColumnContracts(handlerPurchaseContractList)

  const handlerBillList = async () => {
    try {
      setLoader(true)
      let result = await getApi(`${BILLS_API}?billType=0`);
      result = result.res.bill_list.filter((item) => item.Status === VENDOR_VP_PENDING);
      setLoader(false)
      setBills(result)
    } catch (error) {
      console.log(error);
    }
  }

  const billcolumns = useFeedsColumnBills(handlerBillList)

  const handlerIndividualBillList = async () => {
    try {
      setLoader(true)
      let result = await getApi(`${BILLS_API}?billType=1`);
      result = result.res.bill_list.filter((item) => item.Status === VENDOR_VP_PENDING);
      setLoader(false)
      setIndividualBills(result)
    } catch (error) {
      console.log(error);
    }
  }

  const individualbillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList)

  const handlerReportsPieChart = async () => {
    try {
      setLoader(true)
      let result = await getApi(PENDING_REPORTS);
      let data = result.res.series;
      let label = result.res.labels;
      setLoader(false)
      setReportsPieChart(data)
      setLabel(label)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerSpendPieDonutChart = async () => {
    try {
      setLoader(true)
      let result = await getApi(SPEND_ITEM);
      let data = result.res.totalAmount;
      let label = result.res.itemName;
      setLoader(false)
      setSpendItemName(label)
      setSpendTotalAmout(data)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerBudgetChart = async () => {
    try {
      setLoader(true)
      let result = await getApi(BUDGET_RECORD);
      const { actualBudget, planBudget, month } = result.res
      let categ = month
      let seriesData = [{
        name: 'Plan Budget',
        type: 'column',
        data: planBudget,
      },
      {
        name: 'Actual Budget',
        type: 'line',
        data: actualBudget
      }];
      setLoader(false)
      setBudgetRecord(seriesData)
      setBudgetMonths(categ)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerSpendTrendChart = async () => {
    try {
      setLoader(true)
      let result = await getApi(BUDGET_RECORD);
      const { actualBudget, month } = result.res
      let categ = month
      let seriesData = [{
        name: 'Spend ',
        data: actualBudget
      }];
      setLoader(false)
      setSpendTrend(seriesData)
      setBudgetMonths(categ)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerVendorSpendChart = async () => {
    try {
      setLoader(true)
      let result = await getApi(VENDOR_SPEND);
      const { totalSpend, vendorName } = result.res
      let categ = vendorName
      let seriesData = [{
        name: 'Spend',
        data: totalSpend
      }];
      setLoader(false)
      setVendorSpend(seriesData)
      setVendorName(categ)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerTotalExpense = async () => {
    try {
      setLoader(true)
      let result = await getApi(TOTAL_EXPENSE);
      let data = result.res.series;
      setExpencePayment(data)
      setLoader(false)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleVendorList();
    handlerPurchaseContractList()
    handlerBillList()
    handlerIndividualBillList()
    handlerReportsPieChart();
    handlerTotalExpense();
    handlerBudgetChart();
    handlerSpendTrendChart();
    handlerVendorSpendChart();
    handlerSpendPieDonutChart();
  }, []);

  return (

    <>
      <Helmet>
        <title>Vendor Portal || Home</title>
      </Helmet>

      {loader ?
        <Loader /> :
        <>
          <div className='col-12 mb-2'>
            <div className="row g-2">
              <div className="col-12 col-lg-4">
                <div className='container-section mb-0'>
                  <div className="card-body cstm-card-color1">
                    <div className='d-flex justify-content-between'>
                      <div className='mb'>
                        <h4><span class="me-1">₹</span>{expenseData.LastMonthExpense ? parseFloat(expenseData.LastMonthExpense).toLocaleString('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) : '0.00'}</h4>
                        <p>Total Expenses</p>
                        <div className='d-flex align-items-center'>
                          <span>Last Month</span>
                        </div>
                      </div>
                      <div className='d-flex justify-content-center align-items-center'>
                        <img src={exp1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className='container-section mb-0'>
                  <div className="card-body cstm-card-color2">
                    <div className='d-flex justify-content-between'>
                      <div className=''><h4><span className='me-1'>₹</span>{expenseData.CurrentMonthExpense ? parseFloat(expenseData.CurrentMonthExpense).toLocaleString('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) : '0.00'}</h4>
                        <p>Total Expenses</p>
                        <div className='d-flex  align-items-center'>
                          <span>Current Month</span>
                        </div>
                      </div>
                      <div className='d-flex justify-content-center align-items-center'>
                        <img src={exp2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className='container-section mb-0'>
                  <div className="card-body cstm-card-color3">
                    <div className='d-flex justify-content-between'>
                      <div className=''><h4><span className='me-1'>₹</span>{expenseData.CurrentYearExpense ? parseFloat(expenseData.CurrentYearExpense).toLocaleString('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) : '0.00'}</h4>
                        <p>Total Expenses</p>
                        <div className='d-flex  align-items-center'>
                          <span>Yearly</span>
                        </div>
                      </div>
                      <div className='d-flex justify-content-center align-items-center'>
                        <img src={exp3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className='container-section mb-0'>
                  <div className="card-body cstm-card-color4">
                    <div className='d-flex justify-content-between'>
                      <div className=''><h4><span className='me-1'>₹</span>{expenseData.LastMonthPendingPayment ? parseFloat(expenseData.LastMonthPendingPayment).toLocaleString('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) : '0.00'}</h4>
                        <p>Total Pending Payments</p>
                        <div className='d-flex  align-items-center'>
                          <span>Last Month</span>
                        </div>
                      </div>
                      <div className='d-flex justify-content-center align-items-center'>
                        <img src={pay1} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className='container-section mb-0'>
                  <div className="card-body cstm-card-color5">
                    <div className='d-flex justify-content-between'>
                      <div className=''><h4>
                        <span className='me-1'>₹</span>{expenseData.CurrentMonthPendingPayment ? parseFloat(expenseData.CurrentMonthPendingPayment).toLocaleString('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) : '0.00'}</h4>
                        <p>Total Pending Payments</p>
                        <div className='d-flex  align-items-center'>
                          <span>Current Month</span>
                        </div>
                      </div>
                      <div className='d-flex justify-content-center align-items-center'>
                        <img src={pay2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className='container-section mb-0'>
                  <div className="card-body cstm-card-color6">
                    <div className='d-flex justify-content-between'>
                      <div className=''>
                        <h4><span className='me-1'>₹</span>{expenseData.CurrentYearPendingPayment ? parseFloat(expenseData.CurrentYearPendingPayment).toLocaleString('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) : '0.00'}</h4>
                        <p>Total Pending Payments</p>
                        <div className='d-flex  align-items-center'>
                          <span>Yearly</span>
                        </div>
                      </div>
                      <div className='d-flex justify-content-center align-items-center'>
                        <img src={pay3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-lg-12">
                            <div className='container-section mb-0'>
                                <div className="card-body">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <h5>Select Date Range Picker</h5>
                                        </div>
                                        <div className='align-items-center'>
                                            <DateRangeCalender />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
          <div className='col-12 mb-2'>
            <div className="row g-2">
              <div className="col-12 col-lg-6">
                <div className='container-section mb-0'>
                  <div className="card-body">
                    <PieChart
                      title='Approval Queue'
                      data={reportsPieChart}
                      label={label} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className='container-section h-100 mb-0'>
                  <div className="card-body">
                    <PieDonutChart
                      title='Analyzing Spend'
                      data={spendTotalAmout}
                      label={spendItemName} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className='container-section mb-0'>
                  <div className="card-body">
                    <GroupedBarChart />
                    {/* <BarChart
                                        title='Payment Report'
                                        data={paymentBarChart}
                                        categories={paymentBarChartCategory}
                                    /> */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className='container-section mb-0'>
                  <div className="card-body">
                    <MultipleBarChart
                      title='Spend on Vendor'
                      categories={vendorName}
                      data={vendorSpend}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className='container-section'>
                  <div className="card-body">
                    <LineColumnChart
                      title='Budget Record'
                      data={budgetRecord}
                      categories={budgetMonths} />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className='container-section'>
                  <div className="card-body">
                    <LineChart
                      title='Spend Trend'
                      data={spendTrend}
                      categories={budgetMonths}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DashboardCard title={"Vendors"} columns={columns} data={vendors} mt="mt-4" fn="" />
          <DashboardCard title={"Purchase Contracts"} columns={purchasecolumns} data={purchaseContract} mt="mt-4" fn="" />
          <DashboardCard title={"Bills"} columns={billcolumns} data={bills} mt="mt-4" fn="" />
          <DashboardCard title={"Standalone Bill"} columns={individualbillcolumns} data={individualBill} mt="mt-4" fn="" />
        </>
      }
    </>

  )
}

export default VpDashboard















